/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The trigger that lead to this safety prediction
 */
export enum SafetyPredictionTrigger {
  LINE_FORCE = 'LINE_FORCE',
  FENDER_DEFLECTION = 'FENDER_DEFLECTION',
  BOLLARD_FORCE = 'BOLLARD_FORCE',
  MOTION_SURGE = 'MOTION_SURGE',
  MOTION_SWAY = 'MOTION_SWAY',
  MOTION_YAW = 'MOTION_YAW',
  MOTION_HEAVE = 'MOTION_HEAVE',
  MOTION_ROLL = 'MOTION_ROLL',
  MOTION_PITCH = 'MOTION_PITCH',
}

import { createSearchParams, useNavigate } from 'react-router-dom';
import { Moment } from 'moment';
import type { LoadingCondition, SafetyPredictionTrigger, VesselDisposition } from '@/api/ui/requests';
import { SafetyPredictionTriggerStrings } from '@/types';

type BerthSafetyNavigationProps = {
  berthId: string;
  isOperationalVessel: boolean;
  dmaCaseId?: string;
  loadingCondition?: LoadingCondition;
  vesselDisposition?: VesselDisposition;
  trigger?: SafetyPredictionTrigger;
  predictionTimestamp?: Moment;
  vesselMmsi?: string;
};

const searchParamsBuilder = (searchParams: URLSearchParams, name: string, value?: string) => {
  value && searchParams.set(name, value);
};

function useToBerthSafetyNavigate() {
  const navigate = useNavigate();

  const navigateToBerthSafety = ({
    berthId,
    loadingCondition,
    trigger,
    dmaCaseId,
    isOperationalVessel,
    vesselDisposition,
    predictionTimestamp,
    vesselMmsi,
  }: BerthSafetyNavigationProps) => {
    const warningTrigger = SafetyPredictionTriggerStrings[trigger];
    // if predictionTimestamp hour is less than 2.am, minus 2 hours from start of the day.
    // So that, the graph will have a little offset util the marked timestamp.
    const searchTimestamp =
      predictionTimestamp?.hour() < 2 ? predictionTimestamp?.startOf('day').subtract(2, 'hours') : predictionTimestamp?.startOf('day');
    const searchParams = createSearchParams();
    searchParamsBuilder(searchParams, 'dmaCaseId', dmaCaseId);
    searchParamsBuilder(searchParams, 'loadingCondition', loadingCondition);
    searchParamsBuilder(searchParams, 'date', searchTimestamp?.toISOString(true));
    searchParamsBuilder(searchParams, 'disposition', vesselDisposition);
    searchParamsBuilder(searchParams, 'graph', warningTrigger?.graphName);
    searchParamsBuilder(searchParams, 'motion', warningTrigger?.motion);
    searchParamsBuilder(searchParams, 'period', '1');

    const basePath = isOperationalVessel
      ? `/safety-prediction/operational/berths/${berthId}/vessels/${vesselMmsi}`
      : `/safety-prediction/simulation/berths/${berthId}`;

    navigate({
      pathname: basePath,
      search: searchParams.toString(),
    });
  };

  return {
    navigateToBerthSafety,
  };
}

export default useToBerthSafetyNavigate;

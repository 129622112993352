import { useId } from 'react';
import { Cancel } from '@mui/icons-material';
import { Box, Chip, FormControl, InputLabel, MenuItem, Select as MuiSelect, OutlinedInput, SelectChangeEvent } from '@mui/material';
import { SelectProps } from '@/components/common/Select/types';

function Select<TOption, TValue>({
  options,
  value,
  displayFunction,
  valueFunction,
  onChange,
  label,
  placeHolder,
  required,
  fullWidth,
  hasEmptyOption,
  name,
  size = 'medium',
  multiple = false,
  onClose = undefined,
  renderValue = undefined,
  formControlSx = undefined,
  menuProps = undefined,
}: SelectProps<TOption, TValue>) {
  const id = useId();

  return (
    <FormControl fullWidth={fullWidth} size={size} sx={formControlSx}>
      <InputLabel id={`label-${id}`}>
        {label} {required ? ' *' : null}
      </InputLabel>
      <MuiSelect
        labelId={`label-${id}`}
        name={name}
        data-testid={name ? `select-${name}` : undefined}
        input={<OutlinedInput label={label} />}
        value={value}
        onChange={(event: SelectChangeEvent<TValue>) => onChange(event.target.value as TValue, event.target.name)}
        onClose={onClose ? () => onClose(value) : undefined}
        required={required}
        multiple={multiple}
        sx={{ '& .MuiSelect-select': { textOverflow: 'ellipsis' } }}
        renderValue={renderValue}
        MenuProps={menuProps}
      >
        {hasEmptyOption ? (
          <MenuItem value="">
            <em>{placeHolder ? placeHolder : 'None'}</em>
          </MenuItem>
        ) : null}
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={(valueFunction ? valueFunction(option) : option) as unknown as string}
            disabled={valueFunction && !valueFunction(option)}
          >
            {displayFunction ? displayFunction(option) : (option as string)}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

export default Select;

export function SelectedChips({ values, onDeletedChip }: { values: string[]; onDeletedChip: (_: string) => void }) {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {values.map((item) => (
        <Chip
          sx={{ height: 'fit-content' }}
          key={item}
          label={item}
          clickable={true}
          color={'secondary'}
          deleteIcon={<Cancel sx={{ height: 18 }} onMouseDown={(event) => event.stopPropagation()} />}
          onDelete={() => onDeletedChip(item)}
        />
      ))}
    </Box>
  );
}
